// $primary-color:#7B88B8;
:root {
    --default-primary-color: #394B8A;
}
$primary-color: var(--default-primary-color);
$primary-Sonic-Silver: #777777;
$primary-Spanish-Gray: #9b9b9b;
$primary-Jungle-Green: #239d80;
$Green-Crayola: #0ead7c;
$primary-linear-background: linear-gradient(180deg, rgba(35, 157, 128, 0.93) 0%, rgba(35, 83, 157, 0.93) 100%);
$primary-Platinum: #eae7e3;

$secondary-Gray: #bebebe;
$secondary-Smoke-White: rgb(229, 229, 229);
$secondary-Ghost-White: #fbf9f6;
$secondary-Silver-Sand: #c4c4c4;
$secondary-Celadon-green: #1e8970;
$secondary-Tufts-Blue: #4282e2;
$secondary-Light-Grey: #dad7d0;
$secondary-Arsenic: #3d454d;
$secondary-Taupe-Gray: #888888;
$secondary-linear-background: linear-gradient(180deg, #239d80 0%, #23539d 100%);
$background-ghost-white: #faf8f7;
$text-Black-Olive: #3b3b3b;
$background-Cultured: #f5f6f8;
$text-egyptian-blue: #1f2f9c;
$text-lavender-blue: #c7dbf6;
$background-lemon-chiffon-color: #fff9c8;
$background-lavender: #f1ddff;
$title-graphite: #6d7278;
$title-charcoal: #333333;
$white-smoke: #f5f5f5;
$secondary-black: #000;
$secondary-platinum: #e7e7e9;
$sova-white: #fafafa;
$secondary-white: #ffffff;
$transparant-black: #00000033;
$severity-Electric-Red: #e30303;
$button-border: #d8d8d8;
$secondary-cultered: #f7f7f7;
$Spanish-Gray: #9b9b9b;
$Cultured: #f5f5f5;
$Silver-Sand: #c3c3c3;
$Spanish-Gray2: #9a9a9a;
$Tufts-Blue: #4282e2;

$Platinum: #e3e3e3;
$Bright-Gray: #eeeeee;
$Old-Lace: #fff2e0;
$Dark-Charcoal: #323232;
$Dark-Charcoal2: #333333;
// $Green-Crayola:#1F9C7B;
$Light-Grey: rgba(0, 0, 0, 0.51);
$Mountain-Mist: #979797;
$Cyan-Blue: #eaf5ff;
$Lime-Gray: #ededed;
$border-grey: #d5d5d5;
$mist-bleach: #f7f9fa;
$sova-blue: #32325d;
// new colors added for nextgen HTML
$light-blue: #8898aa;
$Quartz: #4a4a4a;
$Dark-Liver: #4d4d4d;
$Sonic-Silver: #757575;
$Chinese-Silver: #cccccc;
$Granite-Gray: #666666;
$Black: #000000;
$Eerie-Black: #181818;
$Anti-Flash-White: #f0f0f0;
$Kiwi: #87d340;
$Han-Purple: #5c38f5;
$sea-Serpent: #5bdfbd;
$Deep-Carrot-Orange: #e1702c;
$Persian-Red: #ce372d;
$Moon-Mist: #dddddd;
$Seashell: #f5f6f8;
$Violet-Blue: #394bba;
$Silver-Chalice:#ABABAB;
$royal-blue: #394b8a;
$sova-light-grey: #cbcbcb;
$sova-light-blue:#1989F0;
$Light_Mustard:#FFD451;
$Gun_metal:#2F3234;
$Anti-Flash-White:#F3F4F6;
$Cyan-Blue-Azure:#428BCA;
$Platinum-silver:#E3E6EA;
