// imports
@import "../colors/colors";
@import "../mixins/padding";
@import "./button";

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.hidden {
  display: none !important;
}
.request_list_header {
  min-height: 36px;
  padding: 8px 11px;
  span:first-child {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  svg {
    height: 15px;
    width: 15px;
  }
  .request_list_header_text {
    @include text($Dark-Liver, 12px, 600, 20px, normal);
  }
}
.repair__container {
  padding: 37px 50px 20px 50px !important;
}
.filter__container {
  padding: 6px 15px 20px 10px !important;
  position: fixed;
  width: 300px;
  right: -8px;
  top: 0px;
  height: 100vh;
  overflow-y: scroll;
}
.close__icon {
  display: flex;
  justify-content: flex-end;
  svg {
    &:hover {
      cursor: pointer;
    }
  }
}
.rl__toggler__container {
  padding: 0px 5px 0px 15px;
  min-height: 50vh;
}
.border-paper {
  border: 1px solid #f5f6f8 !important;
  border-radius: 5px !important;
}
.rl__toggler {
  display: flex;
  gap: 5px;
  align-items: center;
  .filter__box {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .filter_rating_color {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    @include flexCenterAll();
    svg {
      width: 10px;
      height: 10px;
      color: $secondary-white !important;
    }
  }
}
.switch {
  .Mui-checked {
    color: $primary-color !important;
    .MuiSwitch-thumb {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.12),
        1px 2px 1px rgba(0, 0, 0, 0.237602);
      border: none;
    }
  }
  .MuiSwitch-thumb {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.12),
      1px 2px 1px rgba(0, 0, 0, 0.237602);
    border: 1px solid $Anti-Flash-White;
  }
  .MuiSwitch-track {
    background-color: #d3d3d3 !important;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.12),
      1px 1px 1px rgba(0, 0, 0, 0.237602);
  }
  .Mui-checked + .MuiSwitch-track {
    background-color: #2c3a6ee3 !important;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12),
      0px 1px 1px rgba(0, 0, 0, 0.237602);
  }
}
.filter__title {
  @include text($Dark-Liver, 22px, 600, 32px, normal);
  margin-bottom: 10px;
}
.filter__subtitle {
  @include text($text-Black-Olive, 16px, 400, 24px, normal);
  margin-bottom: 15px !important;
}
.request__list__paper {
  // padding: 24px !important;
  min-height: 83px !important;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1) !important;
}
.rl__buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.p_cost {
  padding: 9px 14px;
  border-radius: 12px !important;
}
.rl__total__costtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  span:first-child {
    @include text($Granite-Gray, 12px, 400, 16px, normal);
    text-transform: uppercase;
  }
}
.rl__cost__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span:first-child {
    @include text($Eerie-Black, 16px, 400, 24px, normal);
    text-transform: capitalize;
  }
  span:last-child {
    @include text($Granite-Gray, 16px, 400, 24px, normal);
  }
}
.repair__selection__wrapper {
  cursor: pointer;
  position: relative;
  z-index: 5;
}
.repair__comment__box {
  margin-top: 24px;
}
.repair__selection__icon {
  @include flexCenterAll();
  width: 36px;
  height: 36px;
  border-radius: 6px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1) !important;
  position: relative;
  z-index: 15;
  background-color: $secondary-white;
}
.repair__selection__icon.active {
  background-color: $primary-color;
  transition: background-color 0.8s ease;
  svg {
    color: $secondary-white !important;
  }
}
.repair__comment__info {
  position: relative;
  position: absolute;
  overflow: visible !important;
  right: 0px;
  top: 0px;
  // z-index: 2;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1) !important;
  .p_comment {
    padding: 10px 16px 16px 24px !important;
  }
  .horizontal__toggle__arrow__left {
    position: absolute;
    font-size: 16px !important;
    top: 43%;
    left: -11px;
    background-color: $secondary-white;
    box-shadow: 0px -2px 2px 0.25px rgba(0, 0, 0, 0.1), 0px 2px 6px 2px #fff !important;
    padding: 6px;
    border-radius: 50%;
    color: $Mountain-Mist !important;
    transform: rotate(-90deg);
    // z-index: -1;
    svg {
      font-size: 16px !important;
    }
  }
}
.rl__input_textarea textarea {
  padding: 4px 7px;
  min-height: 50px!important;
  max-height: 85px;
  overflow: scroll!important;
}
.repair__icons__card {
  opacity: 0;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 25px;
  min-height: 125px;
  z-index: 1;
  padding-top: 15px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1) !important;
  .icon__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-around;
    align-items: center;
  }
}
.repair__icons__card.active_icons {
  opacity: 1;
  overflow: visible;
  .selected_icon {
    color: $primary-color !important;
  }
}
.repair__icons__card.height {
  height: 140px;
  transition: height 4s;
}
.horizontal__toggle__arrow {
  transform: rotate(270deg);
  color: $Mountain-Mist !important;
  position: absolute;
  font-size: 16px !important;
  top: 43%;
  left: -10px;
  background-color: $secondary-white;
  box-shadow: 0px -1px 1px 0.25px rgba(0, 0, 0, 0.1), 0px 2px 6px 2px #fff !important;
  padding: 5px;
  border-radius: 50%;
  color: $Mountain-Mist !important;
  z-index: -1;
  svg {
    transform: rotate(180deg);
    font-size: 16px !important;
  }
}

.repair__title {
  @include text($Silver-Chalice, 12px, 400, 16px, normal);
  text-transform: uppercase;
}
.rl__input {
  justify-content: space-between;
  display: flex;
}
.rl__input__icon {
  padding-left: 15px;
}
.cost__collapsed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px !important;
  gap: 10px;
  word-break: break-all;
  span:first-child {
    display: flex;
    gap: 5px;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  span:last-child{
    min-width: 50px;
    text-align: end;
  }
}
// swipper css
.swipable_box {
  min-height: 200px !important;
}
// .MuiDrawer-root{
//     z-index: 1300!important;
// }
.puller {
  top: 11px !important;
  width: 100px !important;
  left: calc(50% - 50px) !important;
}
.swiper_status_text {
  text-align: center;
  margin: 18px !important;
  @include text($Dark-Liver, 8px, 700, 32px, normal);
  text-transform: uppercase !important;
}
.swipper_initial {
  top: -150px !important;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1) !important;
}
.swiper_initial_opened {
  top: -58px !important;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1) !important;
}
.swiper_initial_box {
  text-align: left;
  .request__list__paper {
    // padding: 24px !important;
    min-height: 33px !important;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1) !important;
  }
}
.swiper__content {
  margin-top: 20px !important;
}
.PrivateSwipeArea-root {
  display: none !important;
}

// create request list modal
.req__modal .MuiPaper-rounded {
  border-radius: 0px !important;
}
.req__modal .MuiDialogContent-root{
    overflow-x: hidden;
}
.req__modal__container {
  padding: 24px 40px;
  min-width: 570px;
}
.req__modal__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 33px;
  span:first-child {
    @include text($Dark-Liver, 22px, 600, 32px, normal);
  }
  span:last-child {
    cursor: pointer;
  }
  svg {
    color: $Quartz !important;
    font-size: 24px;
  }
}
.req__modal__subhead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  span:first-child {
    display: flex;
    align-items: center;
    svg {
      font-size: 16px;
      color: $primary-color;
      margin-right: 10px;
    }
  }
}
.req__modal__email__container {
  margin-bottom: 8px;
  div {
    margin-bottom: 2px;
  }
  span {
    @include text($primary-color, 16px, 600, 24px, normal);
    // display: flex;
    // align-items: center;
    // gap: 5px;
    // margin-top: 12px;
    cursor: pointer;
  }
  svg {
    font-size: 16px;
    color: $primary-color;
  }
}
.req__modal__subject {
  margin-bottom: 30px;
  label {
    color: $primary-color !important;
  }
}
.req__modal__textarea textarea {
  padding: 4px 7px;
}
.req__modal__action {
  margin-top: 28px;
  display: flex;
  justify-content: flex-end;
}
.p-5 {
  padding: 5px !important;
}
.comment_rating__outer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}
.comment_rating__inner{
    display: flex;
    flex-wrap: wrap;
}
.comment_rating__outer.is_mobile {
  justify-content: space-between;
}
@media only screen and (max-width: 900px) {
  .req__modal .MuiDialogContent-root {
    padding: 0px !important;
  }
  .req__modal__container {
    min-width: 350px;
  }
  .filter__container {
    display: none;
  }
  .mobile_create_request_list {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 20px;
  }
  .PrivateSwipeArea-root {
    display: block !important;
  }
  .repair__container {
    padding: 5px 35px 180px 35px !important;
  }
  // .comment_rating__outer{
  //     justify-content: flex-start!important;
  // }
}
@media only screen and (max-width: 900px) {
  .repair__comment__info .MuiCard-root {
    min-width: 280px !important;
  }
  .rl__input__icon {
    padding-left: 7px;
  }
  .comment_rating__inner{
    display: block;
}
}
@media only screen and (max-width: 900px) {
  .repair__container {
    padding: 5px 0px 180px 0px !important;
  }
}
