@import "../colors/colors";
@import "../mixins/_styles.scss";
@import '../mixins/_fonts.scss';

.preview__right{
    padding-left: 30px;
}
.preview__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 30px;
}
.header__l{
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    span{
        @include text($Black, 16px, 400, 24px, normal);
    }
}
.categories__title_preview {
    @include text($Dark-Liver, 22px, 600, 32px, 0.53px);
    margin-top: 22px!important;
    margin-bottom: 15px !important;
}
.header__r{
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    span{
        @include text($Quartz, 14px, 400, 20px, normal);
    }
}
.preview__inner__container{
    padding: 73px 50px;
}
.request__details{
    .preview__property__details{
        p:first-child{
            @include text($Black, 16px, 400, 24px, normal);
        }
        p:nth-child(2){
            @include text($Dark-Liver, 36px, 600, 32px, normal);
            margin: 8px 0px;
        }
        p:last-child{
            @include text($text-Black-Olive, 20px, 600, 32px, normal);
            // margin-bottom: 50px;
        }
    }
}
.preview__total{
    border: 1px solid #c1c1c1 !important;
    border-radius: 12px !important;
    padding: 16px 24px 16px 24px !important;
    .total__line{
        display: flex;
        justify-content: space-between;
        span:first-child{
            @include text($text-Black-Olive, 16px, 400, 24px, normal);
        }
        span:last-child{
            @include text($primary-color, 16px, 600, 24px, normal);
        }
    }
}
.preview__template_title{
        @include text($secondary-white, 16px, 700, 32px, 0.2px);
        background-color: $primary-color !important;
        padding: 6px 10px;
        text-transform: uppercase;
}
.preview__line__detail__card{
    border: 1px solid #c1c1c1 !important;
    border-radius: 4px !important;
    padding: 8px !important;
    min-height: 110px;
    .preview__detail__head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .outer{
            display: flex;
            gap: 5px;
            align-items: center;
            text-transform: uppercase;
            svg{
                font-size: 14px;
                color: $primary-color;
            }
            .inner{
                @include text($text-Black-Olive, 12px, 400, 17px, normal);
            }
        }
        span:last-child{
            @include text($primary-color, 16px, 600, 16px, normal);
        }       
    }
    p{
        padding: 16px 0px 16px 20px;
    }
}
.comment__content__preview{
    align-items:flex-start
}
.comment__rating__content__preview {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}
.comment__rating__preview {
    margin-right: 10px !important;
    .coment_rating_name {
        @include text($Granite-Gray, 14px, 400, 19px, 0.5px);
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 3px 9px 3px 11px;
        border-radius: 38px;
        width: fit-content;
        text-transform: capitalize;
        span {
            height: 14px;
            width: 14px;
            border-radius: 2px;
            @include flexCenterAll();
            svg {
                height: 12px;
                width: 12px;
                color: $secondary-white !important;
            }
        }
    }
}
.line-title__preview{
        font-style: normal !important;
        font-weight: 600 !important;
        letter-spacing: 0.233333px !important;
        text-transform: uppercase !important;
        color: #3b3b3b !important;
        font-size: 20px !important;
        margin-bottom: 30px !important;
        margin-top: 15px;
}
@media only screen and (max-width: 900px) {
.preview__right{
    padding-left: 0px;
}
.preview__line__detail__card{
    min-width: 280px;
}
.comment__rating__content__preview{
    justify-content:flex-start ;
}
}
@media only screen and (max-width: 600px) {    
.preview__inner__container{
    padding: 73px 12px;
    }
}