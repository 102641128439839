// imports
@import "../colors/colors";
@import "../mixins/padding";
@import "./button";

.tempate_title {
  @include text($secondary-white, 24px, 600, 32px, normal);
  background-color: $primary-color !important;
  padding: 6px 10px;
}

.pdf_tempate_title {
  @include text($secondary-white, 16px, 700, 32px, normal);
  background-color: $primary-color !important;
  padding: 6px 10px;
}

.content_paste_icon {
  color: $primary-color !important;
  height: 32px !important;
  width: 31px !important;
}

.pdf-template-paper {
  width: 100% !important;
  min-height: 80px !important;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1) !important;
  border-radius: 6px !important;
}

.categories__title {
  @include text($Dark-Liver, 22px, 600, 32px, 0.53px);
  margin-bottom: 15px !important;
}
// divider style commented due to design change
// .category__divider{
//     background: $secondary-black!important;
//     height: 2px!important;
//     margin-bottom: 21px!important;
// }
.category__description {
  @include text($text-Black-Olive, 16px, 400, 25px, 0.27px);
}
.cat__description__read_more_text,
.cat__description__read_less_text {
  @include text($Violet-Blue, 16px, 600, 24px, 0.27px);
  color: $primary-color !important;
  font-size: 14px !important;
  cursor: pointer;
  svg {
    margin: -6px !important;
  }
}
.cat__description__read_more,
.cat__description__read_less {
  // div span {
  //   font-size: 16px !important;
  // }
  // body{
  //   font-size: ;
  // }

  ol,
  ul,
  li {
    margin-left: 10px;
  }
}
// comment section
.comment__container {
  padding: 0px 0px 0px 15px;
  min-height: 100px !important;
}
.minHeight200 {
  min-height: 200px;
}
.comment__rating {
  margin-bottom: 16px !important;
  margin-top: 19px !important;
  margin-right: 10px !important;
  .coment_rating_name {
    @include text($Granite-Gray, 14px, 400, 19px, 0.5px);
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 3px 9px 3px 11px;
    border-radius: 38px;
    width: fit-content;
    text-transform: capitalize;
    span {
      height: 14px;
      width: 14px;
      border-radius: 2px;
      @include flexCenterAll();
      svg {
        height: 12px;
        width: 12px;
        color: $secondary-white !important;
      }
    }
  }
}
.comment__title {
  @include text($Granite-Gray, 16px, 600, 24px, 0.266px);
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  span {
    // display: flex;
    align-items: center;
    gap: 5px;
  }
  .circle_icon svg {
    height: 6px;
    width: 6px;
  }
}
.comment__title.comment__mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.comment__description {
  @include text($Sonic-Silver, 15px, 400, 24px, 0.266px);
  padding: 0px 15px !important;
}
.comment__content {
  align-items: center;
}
.comment__rating__content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.comment__location {
  display: flex;
  align-items: center;
  gap: 7px;
  margin: 14px 0px !important;
  padding: 0px 15px !important;
  svg {
    width: 22px;
    height: 22px;
  }
  span {
    @include text($Quartz, 14px, 400, 20px, normal);
    text-transform: capitalize;
  }
}
.html_render {
  font-size: 14px;
  ol,
  ul,
  li {
    margin-left: 10px;
  }
}

.summary_disclaimer {
  border: 1px solid $primary-color;
  border-radius: 6px;
  padding: 13px 20px;
  margin-bottom: 27px;
  @include text($text-Black-Olive, 16px, 400, 24px, normal);
}
// media queries
@media only screen and (max-width: 900px) {
  .comment__rating {
    margin-bottom: 2px !important;
    margin-top: 2px !important;
    margin-right: 2px !important;
  }
  .comment__rating__content {
    justify-content: unset;
  }
}

// .parent-container {
//   /* Ensure the parent container has a maximum width to control responsiveness */
//   // max-width: 600px; /* Adjust this as needed */
//   max-width: 60vw;
//   word-wrap: break-word;
//   // margin: 0 auto; /* Center the container horizontally */
// }

// @media  screen and (min-width: 1000px) {
//   .parent-container {
//     /* Ensure the parent container has a maximum width to control responsiveness */
//     // max-width: 600px; /* Adjust this as needed */
//     max-width: 60vw !important;
//     word-wrap: break-word;
//     // margin: 0 auto; /* Center the container horizontally */
//   }
// }

// @media  screen and (max-width: 1000px) {

// }

.parent-container {
  /* Ensure the parent container has a maximum width to control responsiveness */
  // max-width: 600px; /* Adjust this as needed */
  max-width: auto;
  word-wrap: break-word;
  // margin: 0 auto; /* Center the container horizontally */
}

.overflow {
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  max-width: 100%; /* Set the width to 100% for full responsiveness */
  // border: 1px solid #ccc;
  word-wrap: break-word !important;
  font-size: 16px;
  color: $text-Black-Olive !important;
}

.arrow {
  border: 1px solid $primary-color !important;
  border-radius: 4px !important;
  color: $primary-color !important;
}

.ellipsis-text {
  white-space: nowrap !important; /* Prevent text from wrapping to the next line */
  overflow: hidden !important; /* Hide any overflow beyond the container's width */
  text-overflow: ellipsis !important; /* Display an ellipsis (...) for overflowed text */
  max-width: 65vw !important;
}
