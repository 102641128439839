@import "../colors/colors";
@import "../mixins/padding";
@import "./button";

.line-paper {
  min-height: 100px !important;
  border: 1px solid #c1c1c1 !important;
  border-radius: 5px !important;
  font-family: 'Open sans' !important;
  margin-top: 30px !important;
  padding: 24px 20px 10px 20px !important;
}
.line-title {
  font-style: normal !important;
  font-weight: 600 !important;
  font-family: 'Open sans' !important;
  letter-spacing: 0.233333px !important;
  text-transform: uppercase !important;
  color: #3b3b3b !important;
  font-size: 20px !important;
  margin-bottom: 5px !important;
}
.line-desc {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  font-family: 'Open sans' !important;
  color: $primary-color !important;
  // white-space: pre !important;
  margin-top: 15px !important;
}
.line-info {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  font-family: 'Open sans' !important;
  color: $primary-color !important;
  // white-space: pre !important;
  margin-top: 7px !important;
}
.line-location-button {
  width: 153px !important;
  height: 32px !important;
  border-radius: 20px !important;
  background-color: #f0f0f0 !important;
  color: #4a4a4a !important;
  font-size: 14px !important;
  font-family: 'Open sans' !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  margin-top: 13px !important;
  text-transform: none !important;
}
.line-value {
  font-family: 'Open sans' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  list-style-type: disc !important;
  color: $text-Black-Olive !important;
  p:first-child {
    display: inline !important;
  }
  ol,
  ul,
  li {
    margin-left: 10px;
  }
}

.capitalise {
  text-transform: capitalize;
}

// media queries
@media only screen and (max-width: 900px) {
  .line-paper {
    padding: 16px 14px 28px 14px !important;
  }
}
