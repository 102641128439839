@import "../mixins/padding";
@import "../colors/colors";
@import "../mixins/styles";

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  border-bottom: 1px solid $Chinese-Silver;
  z-index: 10;
}
.delivery_page_container {
  display: flex;
  justify-content: center;
}
.delivery__content {
  width: 100%;
  max-width: 1600px;
}
.delivery__grid {
  padding: 17px 144px;
}
.delivery__grid_row {
  padding: 0px 144px;
}
.delivery-header-containerv1 {
  min-height: 80px;
  background-color: $secondary-white;
  padding: 8px 0px 11px 18px;
}
.delivery-header-logo-containerv1 {
  height: 61px;
  width: 190.13px;
  flex: 33.33%;
  object-fit: contain;
}
.delivery-header-details-containerv1 {
  min-height: 108px;
  width: 100%;
  flex: 50%;
  background-color: $Seashell;
  //   padding: 17px 156px 17px 156px;
  align-items: center !important;
}
.delivery_property_image {
  width: 95px;
  height: 74px;
  object-fit: contain;
  border-radius: 3px;
}
.pl-20 {
  padding-left: 20px !important;
}
.delivery-insp-number {
  @include text($Granite-Gray, 12px, 600, 16px, normal);
  text-decoration: none !important;
}
.delivery-details-addressv1 {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: $text-Black-Olive;
  text-transform: capitalize;
}
.oval {
  width: 72px;
  height: 72px;
  background: $Tufts-Blue;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: #ffffff;
    font-size: 40px;
  }
}
.pa_title {
  span {
    @include text($secondary-Arsenic, 22px, 700, 32px, normal);
  }
}
.pa_subtitle {
  margin-top: 12px;
  margin-bottom: 14px;
  span {
    @include text($secondary-Arsenic, 12px, 700, 20px, normal);
    background: $Light_Mustard;
    padding: 5px 24px;
    border-radius: 12px;
  }
}
.pa_info_text {
  span {
    @include text($secondary-Arsenic, 16px, 400, 24px, normal);
  }
}
.app_grid {
  margin-top: 37px;
}
.qr__section {
  span {
    @include text($secondary-Arsenic, 14px, 700, 24px, normal);
  }
}
.qr__code {
  margin-top: 8px;
  width: 188px;
  height: 188px;
  border: 1px solid #d0d3da;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 180px;
    height: 180px;
  }
}
.get_app {
  margin-top: 31px !important;
  margin-bottom: 32px !important;
  width: 171px !important;
  height: 36px !important;
  border-radius: 3px !important;
  background-color: $secondary-Tufts-Blue !important;
  span {
    @include text($secondary-white, 14px, 700, 24px, normal);
  }
  &:hover {
    background-color: $royal-blue !important;
  }
}
.get_app_ {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  height: 36px !important;
  border-radius: 3px !important;
  background-color: $secondary-Tufts-Blue !important;
  span {
    @include text($secondary-white, 14px, 700, 24px, normal);
  }
  &:hover {
    background-color: $royal-blue !important;
  }
}
.get_app__ {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  margin-left: 10px !important;
  height: 36px !important;
  border-radius: 3px !important;
  background-color: $secondary-Tufts-Blue !important;
  span {
    @include text($secondary-white, 12px, 700, 24px, normal);
  }
  &:hover {
    background-color: $royal-blue !important;
  }
}
.app_features {
  margin-top: 15px;
  span {
    @include text($Gun_metal, 16px, 700, 25px, normal);
  }
}
.feature_list_wrapper {
  margin-top: 34px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}
.f_flex {
  display: flex;
  align-items: center;
  gap: 13px;
}
.f_powered_by {
  position: absolute;
  bottom: 0px;
  right: 0px;
  span {
    @include text($secondary-Arsenic, 14px, 400, 25px, normal);
    svg {
      margin-left: 25px;
    }
  }
}
.pir_subtitle {
  margin-top: 16px;
  margin-bottom: 34px;
  @include text($text-Black-Olive, 16px, 400, 25px, normal);
}
.f_alert {
  margin-left: 5px;
}
.view_report_btn {
  margin-bottom: 32px !important;
  width: 238 !important;
  height: 44px !important;
  border-radius: 3px !important;
  background-color: $secondary-Tufts-Blue !important;
  span {
    @include text($secondary-white, 14px, 700, 24px, normal);
  }
  &:hover {
    background-color: $royal-blue !important;
  }
}

// mobile view css
.m_header {
  height: 55px;
  .delivery-header-containerv1_m {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 114px;
      height: 36px;
      object-fit: contain;
    }
  }
}
.mobile_details {
  min-height: 61px;
  background-color: $Seashell !important;
  img {
    height: 44px;
    width: 44px;
    border-radius: 3px;
  }
}
.delivery-details-addressv1_mobile {
  @include text($Granite-Gray, 11px, 600, 16.5px, normal);
  text-transform: capitalize;
}
.mobile_app_section {
  margin-top: 34px;
}
.oval_mobile {
  min-width: 44px;
  height: 44px;
  background: $Tufts-Blue;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: #ffffff;
    font-size: 29px;
  }
}
.oval_black {
  min-width: 44px;
  height: 44px;
  background: $Quartz;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: #ffffff;
    font-size: 29px;
  }
}
.get_app_mobile_title {
  display: flex;
  gap: 10px;
  p {
    @include text($secondary-Arsenic, 14px, 700, 18px, normal);
  }
}
.mobile_app_title {
  @include text($secondary-Arsenic, 16px, 700, 25px, normal);
  padding: 0px 50px !important;
  text-align: center;
}
.paper_bg {
  background: $Anti-Flash-White !important;
}
.get_app_mobile {
  background-color: $secondary-white !important;
}
.get_app_mobile_btn {
  margin-top: 17px !important;
  margin-bottom: 25px !important;
  width: 100% !important;
  height: 39px !important;
  border-radius: 3px !important;
  background-color: $secondary-Tufts-Blue !important;
  text-transform: uppercase;
  span {
    @include text($secondary-white, 14px, 700, 24px, normal);
  }
  &:hover {
    background-color: $royal-blue !important;
  }
}
.get_app_mobile_btn_team {
  margin-top: 17px !important;
  margin-bottom: 25px !important;
  width: 90% !important;
  height: 39px !important;
  border-radius: 3px !important;
  background-color: $secondary-Tufts-Blue !important;
  text-transform: uppercase;
  span {
    @include text($secondary-white, 14px, 700, 24px, normal);
  }
  &:hover {
    background-color: $royal-blue !important;
  }
}
.collapsible_header {
  .c_header {
    padding-top: 16px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      @include text($secondary-Tufts-Blue, 12px, 700, 20px, normal);
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}
.app_features_mobile {
  margin-top: 12px;
  padding: 0px 27px;
}
.f_powered_by_mobile {
  margin-top: 40px;
  margin-bottom: 23px;
  display: flex;
  justify-content: end;
  padding-right: 30px;
  span {
    @include text($secondary-Arsenic, 14px, 400, 25px, normal);
    svg {
      margin-left: 25px;
    }
  }
}
.mob_modal {
  .MuiPaper-root {
    margin: 10px !important;
  }
  .MuiDialogContent-root {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
}
.app-feature_text {
  @include text($Gun_metal, 12px, 600, 25px, normal);
}
.f_mobile span {
  @include text($secondary-Arsenic, 12px, 400, 16px, normal);
}
.option {
  @include text($secondary-Arsenic, 18px, 700, 32px, normal);
  text-align: center;
  margin-bottom: 5px;
}
.get_app_mobile_title .report_online_text {
  @include text($text-Black-Olive, 12px, 400, 19px, normal);
}
.get_app_mobile_title .report_online_text_title {
  @include text($Dark-Liver, 14px, 700, 18px, normal);
}
.get_app_mobile_title .query_text {
  @include text($text-Black-Olive, 14px, 700, 18px, normal);
}
.contact_wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contact_icon {
  color: $Cyan-Blue-Azure !important;
  display: flex;
  align-items: center;
  gap: 12px;
  span,
  a {
    @include text($text-Black-Olive, 12px, 600, 28px, normal);
    color: $Cyan-Blue-Azure !important;
    text-decoration: none !important;
  }
}
.contact_icon_desk {
  color: $Cyan-Blue-Azure !important;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 14px;
  span,
  a {
    @include text($text-Black-Olive, 16px, 400, 23px, normal);
    line-break: anywhere;
    color: $Cyan-Blue-Azure !important;
    text-decoration: none !important;
  }
}

.ins_agn_title {
  @include text($text-Black-Olive, 12px, 400, 25px, normal);
}
.ins_agn_name {
  @include text($text-Black-Olive, 16px, 600, 25px, normal);
}
.ins_agn_flex {
  margin-top: 19px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.mobile_footer {
  min-height: 79px;
  background-color: $Seashell !important;
  .mobile_footer_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    p {
      @include text($Quartz, 10px, 600, 20px, normal);
    }
  }

  img {
    height: 19px;
    width: 139px;
  }
}
.d_team_head {
  @include text($Dark-Liver, 22px, 700, 32px, normal);
  margin-bottom: 33px;
}
.d_team {
  .ins_ajn_title {
    @include text($text-Black-Olive, 12px, 400, 25px, normal);
  }
  .ins_ajn_name {
    @include text($text-Black-Olive, 16px, 600, 25px, normal);
    margin-bottom: 18px;
  }
}
.rdp_footer {
  height: 134px;
  display: flex;
  align-items: center;
  justify-content: center;
  .delivery-footer-container_v1 {
    display: flex;
    align-items: center;
    gap: 10px;
    .powered-by_v1 {
      @include text($Quartz, 10px, 700, 20px, normal);
    }
    img {
      height: 19px;
      width: 139px;
    }
  }
}
.rdp_app_modal {
  .MuiPaper-root {
    max-width: 880px !important;
    border-radius: 0px !important;
  }
}
.rdp_app_modal_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px !important;
  span,
  svg {
    @include text($secondary-Arsenic, 20px, 700, 24px, normal);
  }
  svg {
    cursor: pointer;
  }
}
.rdp_app_modal_title_res {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px !important;
  span,
  svg {
    @include text($secondary-Arsenic, 18px, 700, 24px, normal);
  }
  svg {
    cursor: pointer;
  }
}
.rdp_app_modal_content {
  margin-top: 64px;
  margin-bottom: 50px;
}
.rdp_app_left_inner {
  margin-bottom: 30px;
  .bg {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    text-align: center;
    @include text($Sonic-Silver, 16px, 900, 25px, normal);
    background: $Platinum-silver;
  }
  .inner_text {
    @include text($Gun_metal, 16px, 600, 25px, 0.3px);
  }
}
.store_images {
  display: flex;
  align-items: center;
  gap: 10px;
}
.mt-8 {
  margin-top: 8px !important;
}
.rdp_app_left_inner_res {
  margin-bottom: 30px;
  .bg {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
    text-align: center;
    @include text($Sonic-Silver, 11px, 900, 18px, normal);
    background: $Platinum-silver;
  }
  .inner_text {
    @include text($Gun_metal, 14px, 600, 22px, 0.3px);
    padding-left: 10px;
    display: block;
  }
}
.light_text {
  @include text($Gun_metal, 16px, 400, 25px, 0.3px);
}
@media only screen and (max-width: 1200px) {
  .dashboard_image img {
    width: 350px;
    height: 250px;
  }
}
@media only screen and (max-width: 1100px) {
  .dashboard_image img {
    width: 300px;
    height: 200px;
  }
}
@media only screen and (max-width: 991px) {
  .delivery__grid {
    padding: 17px 10px;
  }
  .delivery-header-details-containerv1 {
    min-height: 108px;
    width: 100%;
    flex: 50%;
    background-color: $Seashell;
    // padding: 17px 10px 17px 10px;
  }
  .dashboard_image img {
    width: 325px;
    height: 225px;
  }
}
@media only screen and (max-width: 800px) {
  .dashboard_image img {
    width: 300px;
    height: 200px;
  }
}
@media only screen and (max-width: 700px) {
  .dashboard_image img {
    width: 250px;
    height: 150px;
  }
}
.loading_text:after {
  content: ".";
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: rgb(8, 8, 8);
    text-shadow: 0.25em 0 0 rgba(255, 250, 250, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 hsl(0, 0%, 2%), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 rgb(15, 15, 15), 0.5em 0 0 rgb(17, 17, 17);
  }
}
