@import "../colors/colors";
@import "../mixins/padding";

.media-container {
  // margin-top: 130px !important;
  padding: 61px 50px 61px 50px !important;
}
@media only screen and (max-width: 600px) {
  .media-container {
    margin-top: 140px !important;
    padding: 37px 16px 47px 16px !important;
  }
}

.media-box {
  display: flex !important;
  // overflow-x: hidden !important;
}

.animate-div {
  height: 100vh;
}

.image-container {
  padding: 7px !important;
}

.video-container {
  padding: 7px !important;
  position: relative !important;
}

.lib-div {
  display: none;
}

.media-title {
  font-size: 22px !important;
  color: #4d4d4d !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  font-style: normal !important;
  margin-bottom: 25px !important;
}
.submedia-title {
  font-size: 19px !important;
  color: #4d4d4d !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  font-style: normal !important;
  margin-bottom: 25px !important;
}
.media-image-mock {
  width: 100%;
  height: 180px;
  cursor: pointer;
  padding-top: 17%;
  text-align: center;
  background-color: $sova-white;
}

.template-heading-media {
  width: 100%;
  min-height: 42px;
  border-radius: 4px;
  padding: 5px 16px 5px 16px;
  margin-bottom: 20px;
  background-color: $primary-color;
}
.media-template-title {
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 32px !important;
  letter-spacing: 0.2px !important;
  text-transform: uppercase !important;
  color: $secondary-white !important;
}

.media-image-container {
  width: 100%;
  height: 180px;
  cursor: pointer;
  object-fit: scale-down;
  background-color: $sova-white;
}

.media-play-icon {
  cursor: pointer;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  opacity: 80% !important;
  font-size: 75px !important;
  position: absolute !important;
  color: $white-smoke !important;
}

.media-description {
  font-weight: 400;
  font-size: 16px;
  text-align: left !important;
  line-height: 24px;
  overflow-wrap: break-word;
  color: $text-Black-Olive;
}

.view_all {
  padding: 0px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.266667px !important;
  color: $primary-color !important;
  text-transform: none !important;
  margin: 10px 0px 5px 10px !important;
}

.media-play-icon-thumbnail {
  cursor: pointer;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  opacity: 80% !important;
  font-size: 50px !important;
  position: absolute !important;
  color: $white-smoke !important;
}

.narrative-media-description{
  text-align: center !important;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: $text-Black-Olive;
}