// imports
@import "../colors/colors";
@import "../mixins/padding";
@import "./button";

// font import
@font-face {
    font-family: "Open Sans";
    src: url("../../fonts/OpenSans-SemiBold.ttf") format("opentype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

// global
.global-font {
    font-family: "Open Sans" !important;
}
button {
    &:hover {
        background: none !important;
    }
}
.isFlex {
    @include flexCenterAll();
}
.isFlexJustified{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 50px;
}
.isFlex div {
    display: flex;
    justify-content: baseline;
}
.header {
    background-color: $secondary-white !important;
    img {
        max-width: 190px;
        max-height: 61px;
    }
    .top__header{
    //    height: 75px;
    padding: 0px 50px 0px 24px;
    max-width: 3000px!important;
    }
}
.menubar__logo {
    display: flex;
    align-items: flex-end;
    font-size: 12px;
    justify-content: flex-end;
    padding-right: 20px;
    gap: 10px;
    padding-bottom: 5px;
    span {
        padding-bottom: 1px;
        font-size: 11px !important;
        font-weight: bold;
    }
}
.menu__drawer{
    z-index: 99999!important;
}
.navlist {
    @include text($Quartz, 14px, 600, 16px, 0.53px);
    border-bottom: 2px solid $secondary-white;
    padding: 5px 8px;
    // &:hover {
    //     border-bottom: 2px solid $primary-color;
    //     color: $primary-color !important;
    // }
}
.navlink.active .navlist{
    border-bottom: 2px solid $primary-color;
    color: $primary-color !important; 
}
.responsive__menu {
    color: $primary-color !important;
    justify-content: flex-end;
    button {
        padding: 0px !important;
    }
    svg {
        width: 44px;
        height: 44px;
    }
}
.drawer__menu__items {
    svg {
        color: $Quartz !important;
    }
    li {
        color: $Quartz !important;
        padding-left: 12px;
        padding-right: 12px;
        margin-bottom: 5px;
    }
    li div:first-child {
        min-width: 30px !important;
    }
}
.drawer__menu__items.download_pdf{
    margin-top: 20px;
    padding-left: 22px;
    padding-right: 12px;
    margin-bottom: 5px;
    svg {
        color: $primary-color !important;
        margin-right: 8px;
    }
    li {
        color: $primary-color !important;
        padding-left: 12px;
        padding-right: 12px;
        margin-bottom: 5px;
    }
    span{
        text-decoration: underline;
        color: #394B8A!important;
    }
    // li div:first-child {
    //     min-width: 30px !important;
    // } 
}
// 2nd line header
.navbar {
    display: flex;
    padding: 0px 55px 0px 24px;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    color: $Quartz!important;
}
.header__search__bar {
    margin-left: 0px !important;
    .search {
        width: 400px;
        border: 1px solid $Moon-Mist !important;
        border-radius: 4px;
        height: 46px;
    }
}
.header__search__bar__menu {
    margin-top: 12px;
    margin-bottom: 4px;
    .search {
        width: 100%;
        border: 1px solid $Moon-Mist !important;
        border-radius: 4px;
        height: 46px;
    }
}
.close__icon {
    font-weight: bold;
}
.nav__actions {
    @include flexCenterAll();
    gap: 25px;
    a{
        text-decoration: none!important;
        color: $Quartz!important;
    }
}
.nav__actions span {
    @include flexCenterAll();
    gap: 2px;
    cursor: pointer;
}
.responsive_menu {
    width: 100%;
    align-items: center;
    gap: 10px;
    .menu_title{
        white-space: nowrap;
        @include text($Black, 10px, 600, 24px, 0.26px)
    }
}
.nav__mobile{
    background: $Seashell!important;
}
.nav_page_title{
    @include text($text-Black-Olive, 17px, 600, 20px, normal);
    margin-bottom:12px;
}
.responsive_list{
    border-left: 2px solid $primary-color!important;
    margin-left: 10px!important;
    background-color: $background-Cultured!important;
}
.responsive_list_margin{
    margin-left: 12px!important;
}
.header.header_repair_list{
    z-index: 1000!important;
}
// media queries
@media only screen and (max-width: 1220px) {
    .navlink button,
    .navlist {
        @include text($Quartz, 12px, 600, 16px, 0.53px);
    }
}
@media only screen and (max-width: 1120px) {
    .navlink button,
    .navlist {
        @include text($Quartz, 12px, 600, 16px, 0.53px);
    }
}
@media only screen and (max-width: 1050px) {
    .navlink button,
    .navlist {
        @include text($Quartz, 10px, 600, 16px, 0.53px);
    }
}
@media only screen and (max-width: 970px) {
    .navlink button,
    .navlist {
        @include text($Quartz, 9px, 600, 16px, 0.53px);
    }
}
@media only screen and (max-width: 900px) {
    .header {
        .top__header{
        padding: 0px 12px 0px 16px;
        }
    }
    .navbar {
        padding: 12px;
        height: auto;
    }
    .responsive_menu {
        padding-right: 4px;
    }
}
@media only screen and (max-width: 600px) {
    .header__search__bar {
        .search {
            width: 100% !important;
        }
    }
    .responsive_menu {
        padding-right: 4px;
    }
}
