@import "../mixins/padding";
@import "../colors/colors";
@import "../mixins/fonts";

.narrative-details-heading {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px !important;
  color: $Granite-Gray;
}

.narrative-inspection-details-image {
  width: 100%;
  height: 310px;
  object-fit: scale-down;
  margin: 13px 0px 8px 0px;
  background-color: #fafafa;
}

.narrative-details-inspection-name {
  font-family: "Open sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 12.5px !important;
  letter-spacing: 0.27px !important;
  word-wrap: break-word !important;
  margin-top: 8px !important;
  color: #666666 !important;
}

.narrative-details {
  font-family: "Open Sans" !important;
  font-style: normal !important;
  word-wrap: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  overflow-wrap: break-word !important;
  letter-spacing: 0.266667px !important;
  font-weight: 400 !important;
  color: #666666 !important;
  display: flex !important;
  gap: 5px !important;
}

.narrative-details-sendusamessage {
  width: 100% !important;
  padding: 0px !important;
  font-size: 16px !important;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  margin-right: 10px !important;
  text-transform: none !important;
  letter-spacing: 0.5px !important;
  color: $primary-color !important;
  font-family: "Open Sans" !important;
  justify-content: flex-start !important;
}

.narrative-arrow-icon {
  color: $primary-color !important;
  font-size: 14px !important;
}

.narrative-mail-icon {
  width: 18px !important;
  height: 18px !important;
  color: $Quartz !important;
  margin-top: 4px !important;
}

.narrative-client-name {
  color: $text-Black-Olive !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: "Open Sans" !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  overflow-wrap: anywhere !important;
}

.narraative-map-icon {
  height: 20px !important;
  width: 20px !important;
  color: $primary-color !important;
  cursor: pointer;
  margin-top: 5px !important;
}

.narrative-details-divider {
  margin-top: 5px !important;
  color: $Granite-Gray !important;
  margin-bottom: 12px !important;
}

.narrative-details-bold {
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  color: $text-Black-Olive !important;
}
.narrative-details-bold-total {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: $text-Black-Olive !important;
}

.addition_info_container {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px 8px 13px;
}

.addition_info_container:nth-child(odd) {
  background-color: #f5f6f9 !important;
}

.narrative-add-info-divider {
  margin-top: 5px !important;
  color: $Granite-Gray !important;
}

.narrative-details-first-tab {
  display: flex;
  gap: 5px;
}

.service_container {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px 8px 12px;
}

.narrative-service-text {
  color: $text-Black-Olive !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
}

.service_amount_text {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: $text-Black-Olive !important;
  opacity: 60% !important;
}

.service_amount_text_due {
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  color: $text-Black-Olive !important;
  opacity: 60% !important;
}

.narrative-service-divider {
  color: $text-Black-Olive !important;
  border: 1px solid #4a4a4a !important;
}

.narrative-details-invoice {
  width: 100% !important;
  padding: 0px !important;
  font-size: 16px !important;
  margin-top: 20px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  margin-right: 10px !important;
  text-transform: none !important;
  letter-spacing: 0.27px !important;
  color: $primary-color !important;
  font-family: "Open Sans" !important;
  justify-content: flex-start !important;
}

.narrative-details-heading-small {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16.34px !important;
  font-family: 'Open sans' !important;
  color: $Granite-Gray !important;
}

.narrative-details-font-size {
  font-size: 16px !important;
  color: $text-Black-Olive !important;
  font-family: 'Open sans' !important;
}

.property-details-narrative-container {
  display: flex;
  gap: 10px;
  margin-top: 4px;
}

.property-name-container {
  height: 32px;
  padding: 4px 8px 4px 8px;
  background-color: #f5f6f9;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  color: $text-Black-Olive;
  border-radius: 6px;
}

.narrative-inspection-details-date {
  font-family: "Open Sans" !important;
  font-style: normal !important;
  -webkit-user-select: none;
  user-select: none;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: $text-Black-Olive !important;
}

.narrative-summary-note {
  background-color: #f5f6f9;
  padding: 20px;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.27px;
  margin-top: 42px;
  margin-bottom: 50px;
  color: #3b3b3b;
}

.summary-note {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.27px;
  margin-top: 42px;
  margin-bottom: 50px;
  color: #3b3b3b;
}

.narrative-summary-disclaimer {
  margin-top: 40px;
  display: inline-block;
  vertical-align: top;
}
