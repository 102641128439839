// imports
@import "../colors/colors";
@import "../mixins/padding";
@import "../mixins/styles";
@import "../mixins/fonts";

@font-face {
  font-family: "Source Sans pro";
  src: url("https://fonts.googleapis.com/css2?family=Source+Sans+3&display=swap") format("woff"); /* Replace 'your-font-url' with the actual URL of the font file. */
  /* Add more src declarations for different font formats if needed */
}

// bordered button/hollow button
.border__button {
  padding: 10px 15px;
  border-radius: 2px !important;
  font-family: "Open Sans" !important;
  border: 1px solid $primary-color !important;
  height: 43px;
}
// filled button

.filled__button {
  @include text($secondary-white, 14px, 600, 24px, 0.5px);
  text-transform: uppercase;
  background-color: $primary-color !important;
  padding: 10px 17px;
  font-family: "Open Sans" !important;
  border-radius: 2px !important;
  border: 1px solid $primary-color !important;
  height: 44px;
  &:hover {
    background-color: $primary-color !important;
  }
}
.border__btn__text {
  @include text($primary-color, 14px, 600, 24px, 0.5px);
  text-transform: uppercase;
}

.filled__btn__text {
  @include text($secondary-white, 14px, 600, 24px, 0.5px);
  text-transform: uppercase;
  &:hover {
    -webkit-transform: scale(1.03); /* Safari and Chrome */
    -moz-transform: scale(1.02); /* Firefox */
    -ms-transform: scale(1.02); /* IE 9 */
    -o-transform: scale(1.02); /* Opera */
    transform: scale(1.02);
    transition-duration: 0.1s;
  }
}

.count_button {
  font-family: "Source Sans pro", sans-serif !important;
  text-transform: none !important;
  color: #3b3b3b !important;
  font-size: 14px !important;
  line-height: 32px !important;
  cursor: default !important;
}
.mob_menu_nav {
  position: relative;
  height: 100vh;
}
.menu_btn {
  position: absolute;
  bottom: 20px;
  .filled__button_mobile {
    @include text($secondary-white, 14px, 600, 24px, 0.5px);
    text-transform: uppercase;
    background-color: $primary-color !important;
    // padding: 10px 17px;
    margin: 0px 20px;
    font-family: "Open Sans" !important;
    border-radius: 2px !important;
    border: 1px solid $primary-color !important;
    height: 44px;
    width: 100% !important;
    &:hover {
      background-color: $primary-color !important;
    }
    .filled__btn__text {
      @include text($secondary-white, 14px, 600, 24px, 0.5px);
    }
  }
}
.menu_btn_clear{
  position: absolute;
  bottom: 80px;
  left: 15px;
}
.menu_btn_share {
  display: flex;
  justify-content: center;
  .border__button_mobile {
    padding: 10px 17px;
    border-radius: 2px !important;
    font-family: "Open Sans" !important;
    border: 1px solid $primary-color !important;
    height: 43px;
    text-transform: uppercase;
    width: 100% !important;
    margin: 6px 24px !important;
    &:hover {
      background-color: $primary-color !important;
    }
    .border__btn__text {
      @include text($primary-color, 14px, 600, 24px, 0.5px);
      text-transform: uppercase;
    }
  }
}
.border__button.disabled_btn {
  border: 1px solid $title-graphite !important;
  color: $title-graphite !important;
  cursor: not-allowed !important;
}
.border__btn__text.disabled_btn {
  color: $title-graphite !important;
}
.filled__button.disabled_btn {
  background-color: $title-graphite !important;
  border: 1px solid $title-graphite !important;
  cursor: not-allowed !important;
  &:hover {
    background-color: $title-graphite !important;
  }
}
.filled__btn__text.disabled_btn {
  @include text($secondary-white, 14px, 600, 24px, 0.5px);
  text-transform: uppercase;
  &:hover {
    -webkit-transform: scale(1); /* Safari and Chrome */
    -moz-transform: scale(1); /* Firefox */
    -ms-transform: scale(1); /* IE 9 */
    -o-transform: scale(1); /* Opera */
    transform: scale(1);
  }
}
.border__btn__text.disabled_btn {
  @include text($title-graphite, 14px, 600, 24px, 0.5px);
}
.cancel-btn{ 
  @include text($primary-color, 14px, 600, 24px, 0.5px);
  text-transform: uppercase;  
  p{               
      border-bottom: 1px solid $primary-color;
      @include text($primary-color, 14px, 600, 24px, 0.5px);
  }
}
// media queries
@media only screen and (max-width: 1120px) {
  .border__button {
    padding: 10px 11px;
  }
  .filled__button {
    @include text($secondary-white, 12px, 600, 24px, 0.5px);
    padding: 10px 13px;
  }
  .border__btn__text {
    @include text($primary-color, 12px, 600, 24px, 0.5px);
  }
  .filled__btn__text {
    @include text($secondary-white, 12px, 600, 24px, 0.5px);
  }
  .cancel-btn{ 
    @include text($primary-color, 12px, 600, 24px, 0.5px);
    text-transform: uppercase;  
    p{               
        border-bottom: 1px solid $primary-color;
        @include text($primary-color, 12px, 600, 24px, 0.5px);
    }
}
}
.cancel-btn-mobile{
  @include text($primary-color, 16px, 600, 24px, 0.5px);
    text-transform: uppercase;  
    p{               
        border-bottom: 1px solid $primary-color;
        @include text($primary-color, 16px, 600, 24px, 0.5px);
    }
}
@media only screen and (max-width: 1050px) {
  .border__button {
    padding: 10px 11px;
  }
  .filled__button {
    @include text($secondary-white, 10px, 600, 24px, 0.5px);
    padding: 10px 13px;
  }
  .border__btn__text {
    @include text($primary-color, 10px, 600, 24px, 0.5px);
  }
  .filled__btn__text {
    @include text($secondary-white, 10px, 600, 24px, 0.5px);
  }
  .cancel-btn{ 
    @include text($primary-color, 10px, 600, 24px, 0.5px);
    text-transform: uppercase;  
    p{               
        border-bottom: 1px solid $primary-color;
        @include text($primary-color, 10px, 600, 24px, 0.5px);
    }
}
}
@media only screen and (max-width: 970px) {
  .border__button {
    padding: 10px 8px;
    height: 35px;
  }
  .filled__button {
    @include text($secondary-white, 10px, 600, 24px, 0.5px);
    padding: 10px 9px;
    height: 36px;
  }
  .border__btn__text {
    @include text($primary-color, 8px, 600, 24px, 0.5px);
  }
  .filled__btn__text {
    @include text($secondary-white, 8px, 600, 24px, 0.5px);
  }
  .cancel-btn{ 
    @include text($primary-color, 8px, 600, 24px, 0.5px);
    text-transform: uppercase;  
    p{               
        border-bottom: 1px solid $primary-color;
        @include text($primary-color, 8px, 600, 24px, 0.5px);
    }
}
}
