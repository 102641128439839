@import "././assets/styles/mixins/_fonts.scss";
@import "./assets/styles/colors/colors";

.scroller__menu,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: $OpenSans_Regular !important;
  font-size: 14px;
}
ul,
ol,
li {
  margin-left: 10px;
}

*,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: inherit, $OpenSans_Regular; /* Only applies Open Sans if no font-family is inherited */
}
.app_container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.main__container {
  width: 100%;
  max-width: 1800px;
}
@media only screen and (min-width: 1600px) {
  .header {
    left: auto !important;
    right: auto !important;
    max-width: 1800px !important;
  }
  .sidemenu_drawer {
    .MuiPaper-root {
      left: auto !important;
    }
  }
}
@media only screen and (min-width: 1500px) {
  .filter__container {
    right: 1% !important;
  }
}
@media only screen and (min-width: 1600px) {
  .filter__container {
    right: 2% !important;
  }
}
@media only screen and (min-width: 1700px) {
  .filter__container {
    right: 3% !important;
  }
}
@media only screen and (min-width: 1800px) {
  .filter__container {
    right: 6% !important;
  }
}
@media only screen and (min-width: 1900px) {
  .filter__container {
    right: 8% !important;
  }
}
@media only screen and (min-width: 2000px) {
  .filter__container {
    right: 10% !important;
  }
}
@media only screen and (min-width: 2100px) {
  .filter__container {
    right: 12% !important;
  }
}
@media only screen and (min-width: 2200px) {
  .filter__container {
    right: 14% !important;
  }
}
@media only screen and (min-width: 2400px) {
  .filter__container {
    right: 18% !important;
  }
}
