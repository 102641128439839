@import "../mixins/padding";
@import "../colors/colors";

.delivery-header-container {
  min-height: 80px;
  background-color: $secondary-white;
  padding: 8px 0px 11px 130px;
}
.delivery-header-logo-container {
  height: 61px;
  width: 190.13px;
  flex: 33.33%;
  object-fit: contain;
}

.delivery-header-details-container {
  min-height: 108px;
  width: 100%;
  flex: 50%;
  background-color: $Seashell;
  padding: 17px 156px 17px 156px;
}

.delivery-header-details-logo-container {
  width: 95px;
  height: 74px;
  border-radius: 3px;
}
.delivery-details-container {
  padding: 5px 14px 5px 14px;
}

.delivery-support-container {
  min-height: 236px;
  width: 100%;
  background-color: $royal-blue;
  padding: 55px 0px 42px 185px;
  display: flex;
}

.delivery-details-title {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $Granite-Gray;
}

.delivery-details-address {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: $text-Black-Olive;
}
.delivery-details-date {
  margin-top: 2px;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.266px;
  color: $Granite-Gray;
}

.delivery-agent-pic {
  width: 68px;
  height: 68px;
  border-radius: 50%;
}
.delivery-footer-container {
  min-height: 49px;
  width: 100%;
  display: flex;
  background-color: $Seashell;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 13px;
}

.delivery-support-icon {
  width: 68px;
  height: 68px;
  padding: 16px;
  border-radius: 50%;
  background-color: $secondary-white;
}

.delivery-support-details {
  padding: 2px 0px 10px 20px;
}

.delivery-support-details-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: $secondary-white;
  margin-bottom: 2px;
}

.delivery-support-details-subheading {
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  letter-spacing: 0.266px;
  color: $secondary-white;
}

.powered-by {
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  color: $Quartz;
}

.delivery-content {
  min-height: 150px;
  padding: 65px 170px 48px 170px;
}
.delivery-content-logo {
  width: 68px;
  height: 68px;
  padding: 14px;
  border-radius: 50%;
  background-color: $royal-blue;
}
.delivery-content-title {
  padding: 0px 20px 0px 20px;
}

.delivery-content-title-heading {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  color: $Dark-Liver;
}
.delivery-content-title-subheading {
  letter-spacing: 0.266px;
  line-height: 25px;
  font-weight: 400;
  font-size: 16px;
  color: $text-Black-Olive;
}

.view-html-report {
  width: 219px !important;
  height: 44px !important;
  font-size: 14px !important;
  margin-top: 34px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  border-radius: 3px !important;
  margin-bottom: 36px !important;
  letter-spacing: 0.2px !important;
  color: $secondary-white !important;
  text-transform: uppercase !important;
  background-color: $royal-blue !important;
  &:hover {
    background-color: $royal-blue !important;
  }
}

.delivery-content-link {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 10px;
  text-decoration: underline;
  color: $sova-light-blue;
  cursor: pointer;
}
.delivery-cover-image {
  max-width: 389px;
  height: 246px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: scale-down;
  background-color: $Seashell;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.28);
}
.your-team-pic {
  width: 98.87px;
  height: 31.72px;
  object-fit: scale-down;
}
.your-team-details-container {
  padding: 0px 18px 0px 18px;
}
.your-team-main-name {
  font-weight: 600;
  margin-top: 2px;
  font-size: 18px;
  line-height: 25px;
  color: $text-Black-Olive;
}
.your-team-main-address {
  font-weight: 400;
  margin-top: 10px;
  font-size: 16px;
  line-height: 24px;
  display: inline;
  word-wrap: break-word;
  color: $Granite-Gray;
}
.your-team-main-value {
  font-size: 15px;
  margin-top: 10px;
  margin-left: 4px;
  font-weight: 600;
  display: inline;
  word-wrap: break-word;
  line-height: 24px;
  color: $text-Black-Olive;
}
.team-send-us-message {
  margin-top: 20px !important;
  width: 219px !important;
  height: 44px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 26px !important;
  border-radius: 2px !important;
  margin-bottom: 10px !important;
  color: $royal-blue !important;
  text-transform: uppercase !important;
  border: 1px solid $royal-blue !important;
}

@media (max-width: 767px) {
  .delivery-header-container {
    padding: 10px 0px 11px 0px;
  }
  .delivery-header-details-container {
    padding: 17px 8px 17px 8px;
  }
  .delivery-support-container {
    padding: 55px 0px 42px 8px;
  }
  .delivery-content {
    padding: 30px 15px 20px 15px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .delivery-header-container {
    padding: 15px 0px 11px 16px;
  }
  .delivery-header-details-container {
    padding: 17px 8px 17px 8px;
  }
  .delivery-support-container {
    padding: 55px 0px 42px 8px;
  }
  .delivery-content {
    padding: 20px 8px 20px 8px;
  }
}

// @media only screen and (max-width: 600px) {
//   .delivery-header-container {
//     padding: 10px 0px 11px 8px;
//   }
//   .delivery-header-details-container {
//     padding: 17px 8px 17px 8px;
//   }
//   .delivery-support-container {
//     padding: 55px 0px 42px 8px;
//   }
//   .delivery-content {
//     padding: 20px 8px 20px 8px;
//   }
// }
