$asset-font-path: '../../fonts/';

$OpenSans_Regular_Filename: 'OpenSans-Regular.ttf';
$OpenSans_Bold_Filename: 'OpenSans-Bold.ttf';
$OpenSans_SemiBold_Filename: 'OpenSans-SemiBold.ttf';
$OpenSans_Medium_Filename: 'OpenSans-Medium.ttf';

$OpenSans_Regular: 'Open Sans';
$OpenSans_Bold: 'OpenSans-Bold';
$OpenSans_SemiBold: 'OpenSans-SemiBold';
$OpenSans_Medium: 'OpenSans-Medium';

@font-face {
    font-family: $OpenSans_Regular;
    src: url($asset-font-path + $OpenSans_Regular_Filename) format('opentype');
}

@font-face {
    font-family: $OpenSans_Bold;
    src: url($asset-font-path + $OpenSans_Bold_Filename) format('opentype');
}

@font-face {
    font-family: $OpenSans_SemiBold;
    src: url($asset-font-path + $OpenSans_SemiBold_Filename) format('opentype');
}

@font-face {
    font-family: $OpenSans_Medium;
    src: url($asset-font-path + $OpenSans_Medium_Filename) format('opentype');
}
