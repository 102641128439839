@import "../mixins/padding";
@import "../colors/colors";
@import "../mixins/fonts";

@font-face {
  font-family: "Open sans";
  src: url("../../fonts/OpenSans-Regular.ttf") format("opentype");
}

.container {
  margin: 37px 50px 20px 50px !important;
  font-family: 'Open sans' !important;
}

.scroll__to__top {
  right: 60px !important;
  bottom: 60px !important;
  border-radius: 50% !important;
  width: 56px !important;
  height: 56px !important;
  background: #9b9b9b !important;
  position: fixed !important;
  opacity: 0.3 !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16), 0px 2px 5px rgba(0, 0, 0, 0.26) !important;
  svg {
    width: 22px !important;
    height: 22px !important;
    color: $secondary-white !important;
  }
  &:hover {
    background: #9b9b9b !important;
    opacity: 1 !important;
  }
}

@media screen and (min-width: 1801px) {
  .scroll__to__top {
    margin-right: calc(50% - 900px);
  }
}

//inspection detail layout classes

.inspection-details-title {
  -webkit-user-select: none;
  user-select: none;
  font-size: 22px !important;
  color: #4d4d4d !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  font-style: normal !important;
  font-family: "Open sans" !important;
}
.inspection-details-image {
  width: 100%;
  height: 497px;
  object-fit: scale-down;
  margin-top: 13px;
  border-radius: 6px;
  background-color: #f1f3f4;
}
@media only screen and (max-width: 600px) {
  .inspection-details-image {
    width: 100%;
    height: 198px;
    margin-top: 13px;
    border-radius: 6px;
    font-family: 'Open sans' !important;
  }
  .container {
    margin: 37px 16px 47px 16px !important;
  }
}
.inspection-details-date {
  font-family: "Open sans" !important;
  font-style: normal !important;
  -webkit-user-select: none;
  user-select: none;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000000 !important;
}
.inspection-details-address {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  -webkit-user-select: none;
  user-select: none;
  line-height: 32px !important;
  color: #3b3b3b !important;
  font-family: "Open sans" !important;
  letter-spacing: 0.266667px !important;
}
.inspection-details-viewmap {
  float: right !important;
  font-family: "Open sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  margin-right: 10px !important;
  line-height: 24px !important;
  letter-spacing: 0.5px !important;
  color: $primary-color !important;
  border: 1px solid $primary-color !important;
  border-radius: 2px !important;
  height: 44px !important;
  width: 135.26px !important;
}
.inspection-details-paper {
  width: 100% !important;
  min-height: 250px !important;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1) !important;
  border-radius: 6px !important;
}
.client-details-paper {
  padding: 24px !important;
  min-height: 91px !important;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 20px !important;
}
.referrer-details-paper {
  width: 100% !important;
  margin-top: 20px !important;
  min-height: 201px !important;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1) !important;
  border-radius: 6px !important;
}
.additional-info-paper {
  width: 100% !important;
  // margin-top: 20px !important;
  font-family: 'Open sans' !important;
  min-height: 72px !important;
  padding: 28px 24px 28px 24px !important;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1) !important;
  border-radius: 6px !important;
}

//details classes

.details-heading {
  font-family: "Open sans" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  -webkit-user-select: none;
  user-select: none !important;
  line-height: 16px !important;
  color: #666666 !important;
  cursor: auto;
}
.details-inpector-name {
  font-family: "Open sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  word-wrap: break-word !important;
  line-height: 25px !important;
  color: #3b3b3b !important;
  letter-spacing: 0.5px !important;
  cursor: auto;
  -webkit-user-select: none;
  user-select: none;
}
.details-inspection-name {
  font-family: "Open sans" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 12px !important;
  letter-spacing: 0.266667px !important;
  word-wrap: break-word !important;
  color: #666666 !important;
}
.details-address {
  font-family: "Open sans" !important;
  margin-top: 24px !important;
  font-style: normal !important;
  word-wrap: break-word !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.266667px !important;
  color: #666666 !important;
}
.details-phone-title {
  font-family: "Open sans" !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.266667px !important;
  color: #666666 !important;
}
.details-phone-value {
  justify-content: right !important;
  display: inline-block !important;
  text-align: right;
  text-transform: capitalize;
  font-family: "Open sans" !important;
  font-weight: 600 !important;
  white-space: pre-wrap !important;
  // overflow: hidden !important;
  // text-overflow: ellipsis !important;
  overflow-wrap: break-word !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 24px !important;
  letter-spacing: 0.266667px !important;
  color: #3b3b3b !important;
}
.details-email-value {
  justify-content: right !important;
  display: inline-block !important;
  text-align: right;
  font-family: "Open sans" !important;
  font-weight: 600 !important;
  white-space: pre-wrap !important;
  // overflow: hidden !important;
  // text-overflow: ellipsis !important;
  overflow-wrap: break-word !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: 24px !important;
  letter-spacing: 0.266667px !important;
  color: #3b3b3b !important;
}

.inspection-details-divider {
  margin-bottom: 12px !important;
  margin-top: 12px !important;
}

.details-sendusamessage {
  font-family: "Open sans" !important;
  font-style: normal !important;
  margin-top: 24px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  margin-right: 10px !important;
  line-height: 24px !important;
  letter-spacing: 0.5px !important;
  color: $primary-color !important;
  border: 1px solid $primary-color !important;
  border-radius: 2px !important;
  height: 56px !important;
  width: 100% !important;
}

.details-viewinvoice {
  margin-top: 16px !important;
  height: 24px !important;
  padding: 0px !important;
  font-size: 16px !important;
  font-style: normal !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  letter-spacing: 0.266667px !important;
  color: $primary-color !important;
}

.details-dropdownicon {
  cursor: pointer !important;
  float: right !important;
}

//definitions

.definitions-paper {
  min-height: 82px !important;
  width: 100% !important;
  padding: 18px 26px 18px 26px !important;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1) !important;
  border-radius: 6px !important;
  vertical-align: middle !important;
  margin-top: 12px !important;
  font-family: 'Open sans' !important;
}
.definitions-title {
  font-style: normal !important;
  font-weight: 700 !important;
  font-family: 'Open sans' !important;
  font-size: 16px !important;
  line-height: 25px !important;
  letter-spacing: 0.266667px !important;
  color: #666666 !important;
}
.definitions-desc {
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: 'Open sans' !important;
  color: #3b3b3b !important;
  line-height: 24px !important;
  word-wrap: break-word !important;
  letter-spacing: 0.266667px !important;
}
.definitions-icon {
  width: 39px;
  height: 39px;
  border-radius: 6px;
  padding: 8.5px 10.5px 6.5px 9.1px;
}
.definitions-flag {
  width: 22.5px !important;
  height: 23.1px !important;
  color: #fff !important;
}

//additional info

.additional-info-title {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  vertical-align: middle !important;
  padding: 0px !important;
  font-family: "Open sans" !important;
  line-height: 24px !important;
  letter-spacing: 0.266667px !important;
  text-transform: none !important;
  margin-left: 5px;
  color: #666666 !important;
  pointer-events: none !important;
}

.additional-info-value {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  text-align: right !important;
  font-family: "Open sans" !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
  width: 70%;
  letter-spacing: 0.266667px !important;
  color: #3b3b3b !important;
  float: right !important;
}
.additional-info-icon {
  color: #000000 !important;
  width: 14px !important;
}

.additional-info-divider-vertical {
  margin-left: 18px !important;
  margin-right: 18px !important;
}

.powered_by_PT {
  background-color: $Seashell;
  margin-left: 30px;
  cursor: pointer;
  // position: fixed;
  width: 156px;
  margin-top: 10px;
  // bottom: 0;
}

.powered_by_ISN {
  background-color: $Seashell;
  margin-left: 30px;
  // position: fixed;
  cursor: pointer;
  width: 180px;
  position: relative;
  margin-top: -60px;
  // bottom: 0;
}

.isn_png {
  position: absolute;
  bottom: 0;
  margin-left: 27px;
}

.narrative-inspection-details-address {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  -webkit-user-select: none;
  user-select: none;
  line-height: 32px !important;
  color: #3b3b3b !important;
  font-family: "Open Sans" !important;
  letter-spacing: 0.266667px !important;
}
