@import '../assets/styles/colors/colors';
@media only screen and (min-width: 1800px)  {
    .toastbar {
      margin-left: 1.5% !important;
    }
  }
  
  @media only screen and (min-width: 1900px)  {
    .toastbar {
      margin-left: 4% !important;
    }
  }
  
  @media only screen and (min-width: 2000px)  {
    .toastbar {
      margin-left: 7% !important;
    }
  }
  
  @media only screen and (min-width: 2100px)  {
    .toastbar {
      margin-left: 8% !important;
    }
  }
  
  @media only screen and (min-width: 2200px)  {
    .toastbar {
      margin-left: 10% !important;
    }
  }
  
  @media only screen and (min-width: 2300px)  {
    .toastbar {
      margin-left: 11% !important;
    }
  }
  
  @media only screen and (min-width: 2400px)  {
    .toastbar {
      margin-left: 14% !important;
    }
  }
  