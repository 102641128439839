@import "react-alice-carousel/lib/scss/alice-carousel.scss";

.carousel-modal {
  overflow-x: hidden;
  overflow-y: scroll;
}
// .carousel-modal::-webkit-scrollbar {
//   width: 0px;
//   height: 0em;
// }
.carousel-modal-layout {
  width: 100%;
  height: 100%;

  // background-color: #fff;
  // border: 2px solid #fff;
}

.carousel-navigate-button-next {
  height: 100%;
  color: #cccccc !important;
}
.carousel-close-icon {
  float: right;
  cursor: pointer;
  color: #cccccc;
  margin-bottom: 5px;
}

.carousel-navigate-button-prev {
  height: 100%;
  color: #cccccc !important;
}

.carousel-image-stage {
  width: 100%;
  height: 65vh;
  // background-color: #fff !important;
}

.carousel-image-thumb {
  height: 110px;
  width: 110px;
  cursor: pointer;
}

.carousel-container {
  margin: 20px;
}

.selected-image {
  width: 100%;
  height: 65vh;
  margin-bottom: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.carousel {
  position: relative;
}

.carousel__images {
  display: flex;
  width: 100%;
  overflow-x: hidden;
}

.carousel__image-selected {
  border: 3px solid #fff !important;
}

.carousel__image {
  cursor: pointer;
  min-width: 119px;
  min-height: 119px;
  margin-right: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  border: 3px solid #ffa70000;
  background-position: center center;
}

.carousel__button {
  position: absolute;
  top: 40%;
}

.carousel__button-left {
  left: 10px;
}

.carousel__button-right {
  right: 10px;
}
