// imports
@import "../colors/colors";
@import "../mixins/padding";
@import "./button";

// font import
@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans-SemiBold.ttf") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
.sidemenu_drawer .MuiDrawer-paper {
  height: 100vh !important;
}
.narrative__sidemenu__wrapper {
  background-color: $primary-color !important;
  max-height: 100vh;
  p {
    @include text($secondary-white, 12px, 400, 16px, 0.53px);
    text-transform: uppercase;
    padding: 0px 0px 6px 10px;
  }
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  ul {
    padding: 5px 0px !important;
  }
  li {
    @include text($secondary-white, 14px, 400, 20px, 0.53px);
    &:hover {
      @include text($primary-color, 14px, 400, 20px, 0.53px);
      background-color: $secondary-white !important;
      svg {
        color: $primary-color !important;
      }
    }
    div:first-child {
      @include text($secondary-white, 14px, 400, 20px, 0.53px);
      padding: 5px 0px 5px 13px !important;
      margin-left: -10px !important;
      &:hover {
        @include text($primary-color, 14px, 400, 20px, 0.53px);
        background-color: $secondary-white !important;
      }
      div:first-child {
        min-width: 46px !important;
      }
      div:last-child {
        margin-top: auto;
      }
    }
  }
  .scroller__menu.active {
    li {
      @include text($primary-color, 14px, 400, 20px, 0.53px);
      background-color: $secondary-white !important;

      svg {
        color: $primary-color !important;
      }
      div:first-child {
        @include text($primary-color, 14px, 400, 20px, 0.53px);
        background-color: $secondary-white !important;
      }
    }
  }
  .scroller__menu.disabled {
    li {
      @include text($Chinese-Silver, 14px, 400, 20px, 0.53px);
      svg {
        color: $Chinese-Silver !important;
      }
      div:first-child {
        @include text($Chinese-Silver, 14px, 400, 20px, 0.53px);
        &:hover {
          @include text($Chinese-Silver, 14px, 400, 20px, 0.53px);
          background-color: $Seashell !important;
          cursor: not-allowed;
        }
      }
      &:hover {
        @include text($Chinese-Silver, 14px, 400, 20px, 0.53px);
        background-color: $Seashell !important;
        svg {
          color: $Chinese-Silver !important;
        }
      }
    }
  }
}
.sidemenu__wrapper {
  background-color: $Seashell !important;
  max-height: 100vh;
  p {
    @include text($Dark-Liver, 12px, 400, 16px, 0.53px);
    text-transform: uppercase;
    padding: 0px 0px 6px 10px;
  }
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  ul {
    padding: 5px 0px !important;
  }
  li {
    @include text($text-Black-Olive, 14px, 400, 20px, 0.53px);
    &:hover {
      @include text($primary-color, 14px, 400, 20px, 0.53px);
      background-color: $secondary-white !important;
      svg {
        color: $primary-color !important;
      }
    }
    div:first-child {
      @include text($text-Black-Olive, 14px, 400, 20px, 0.53px);
      padding: 5px 0px 5px 13px !important;
      &:hover {
        @include text($primary-color, 14px, 400, 20px, 0.53px);
        background-color: $secondary-white !important;
      }
      div:first-child {
        min-width: 46px !important;
      }
      div:last-child {
        margin-top: auto;
      }
    }
  }
  .scroller__menu.active {
    li {
      @include text($primary-color, 14px, 400, 20px, 0.53px);
      background-color: $secondary-white !important;

      svg {
        color: $primary-color !important;
      }
      div:first-child {
        @include text($primary-color, 14px, 400, 20px, 0.53px);
        background-color: $secondary-white !important;
      }
    }
  }
  .scroller__menu.disabled {
    li {
      @include text($Chinese-Silver, 14px, 400, 20px, 0.53px);
      svg {
        color: $Chinese-Silver !important;
      }
      div:first-child {
        @include text($Chinese-Silver, 14px, 400, 20px, 0.53px);
        &:hover {
          @include text($Chinese-Silver, 14px, 400, 20px, 0.53px);
          background-color: $Seashell !important;
          cursor: not-allowed;
        }
      }
      &:hover {
        @include text($Chinese-Silver, 14px, 400, 20px, 0.53px);
        background-color: $Seashell !important;
        svg {
          color: $Chinese-Silver !important;
        }
      }
    }
  }
}
.sidemenu__wrapper.full__height {
  min-height: 100vh !important;
}
// mobile view menu css
.menu__template__title {
  margin: 3px 0px 0px 5px !important;
}
.menu__categories__wrapper {
  width: 80vw;
  .menu__box {
    border: 1px solid $Moon-Mist !important;
    border-radius: 3px;
    padding: 6px 15px;
    background: $secondary-white !important;
    svg {
      width: 24px;
      height: 24px;
      color: $Granite-Gray !important;
    }
  }
}
.menu__name {
  @include text($text-Black-Olive, 12px, 600, 12px, 0.3);
}
.menu__item {
  span {
    @include text($text-Black-Olive, 14px, 400, 20px, normal);
  }
}
.list_menu_scroll {
  display: flex;
  align-items: center;
}
.list__menu .MuiPaper-rounded {
  width: 78% !important;
}
* {
  // scrollbar-width: none!important;
  // -ms-overflow-style: none;
  // scrollbar-width: none; /* Firefox */
  // &::-webkit-scrollbar {
  //   display: none!important;
  // }
  ::-webkit-scrollbar {
    // height: 12px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    -webkit-border-radius: 1ex;
  }
}

.seleted_menu {
  background-color: $secondary-white !important;
  color: $primary-color !important;
  svg {
    color: $primary-color !important;
  }
  &:hover {
    background-color: $secondary-white !important;
    color: $primary-color !important;
    svg {
      color: $primary-color !important;
    }
  }
}

.seleted_menu_hover {
  &:hover {
    background-color: $secondary-white !important;
    color: $primary-color !important;
    svg {
      color: $primary-color !important;
    }
  }
}

.narratives_sidemenu_divider {
  margin-top: 3px !important;
  color: $secondary-white !important;
  border-color: $secondary-white !important;
}

.sidemenu_divider {
  margin-top: 3px !important;
  color: $secondary-Silver-Sand !important;
  border-color: $secondary-Silver-Sand !important;
}


