@import "../colors/colors";
.add-recipient-button {
  font-style: normal !important;
  color: $primary-color !important;
  letter-spacing: 0.266667px !important;
  font-size: 16px !important;
  padding: 0px !important;
  margin-right: 10px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  text-transform: capitalize !important;
  margin-top: 30px !important;
}
.add-recipient-button.create_request {
  margin-top: 0px !important;
  display: flex;
  align-items: center;
}
.req__modal__container::-webkit-scrollbar {
  width: 0px;
  height: 2em;
}
.confimation-modal {
  top: 35%;
  left: 50%;
  outline: 0;
  padding: 4px;
  max-width: 597px;
  position: relative;
  margin-bottom: 20%;
  background-color: #fff;
  z-index: 9999;
  border: 2px solid #fff;
  padding: 20px 25px 20px 25px;
  transform: translate(-50%, -25%);
}
.confirmation-title {
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  text-align: center !important;
  margin-top: -20px !important;
  font-size: 22px !important;
  color: #4d4d4d !important;
  margin-bottom: 32px !important;
}
.contactus-done-button {
  width: 146px !important;
  height: 44px !important;
  border-radius: 3px !important;
  background-color: $primary-color !important;
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: 0.2px !important;
  margin-top: 28px !important;
}
.contactus-done-button:hover {
  background-color: $primary-color !important;
}
.contactus-done-button:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
}
.center-done{
  display: flex !important;
  justify-content: center !important;

}
