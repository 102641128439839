@import "../colors/colors";
.modal {
  overflow-x: hidden;
  overflow-y: scroll;
}
.modal::-webkit-scrollbar {
  width: 0px;
  height: 2em;
}
.modal-layout {
  top: 35%;
  left: 50%;
  outline: 0;
  padding: 4px;
  max-width: 697px;
  position: relative;
  margin-bottom: 20%;
  background-color: #fff;
  border: 2px solid #fff;
  padding: 20px 25px 20px 25px;
  transform: translate(-50%, -25%);
}
.modal-title {
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  font-size: 22px !important;
  color: #4d4d4d !important;
  margin-bottom: 32px !important;
}
.close-icon {
  float: right !important;
  cursor: pointer !important;
}
.contactus-texfield {
  padding: 9px;
}
.contactus-messagebox {
  margin-top: 36px !important;
}
.contactus-send-button {
  width: 146px !important;
  height: 44px !important;
  border-radius: 3px !important;
  background-color: $primary-color !important;
  float: right !important;
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: 0.2px !important;
  margin-top: 28px !important;
}
.contactus-send-button:hover {
  background-color: $primary-color !important;
}
.contactus-send-button:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
}
